@import "_variables.scss";

.course-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 280px;
  min-width: 280px;
  border-radius: $border-radius-small;
  background-blend-mode: multiply;
  max-width: 366px;
  max-height: 366px;
  width: 100%;

  .course-action {
    display: flex;
    justify-content: space-between;
    height: 48px;
    padding: 16px 16px 0 16px;
    .tag-default {
      padding: var(--chakra-space-2) var(--chakra-space-4);
    }
    .tag-success,
    .tag-warning {
      padding: var(--chakra-space-2) var(--chakra-space-8);
    }
  }

  .course-details {
    min-height: 80px;
    width: 100%;
    color: $color-default-white;
    padding: var(--chakra-space-6-4);
    border-radius: 0 0 $border-radius-small $border-radius-small;
    background: linear-gradient(rgba(38, 41, 46, 0.8), rgba(38, 41, 46, 0.8));

    .course-details-title {
      font-size: $font-size-reg;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-medium;
    }
    .course-details-type {
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
      line-height: $line-height-small;
      margin: $gap-small 0;
    }
    .course-details-description {
      font-size: $font-size-small;
      font-weight: $font-weight-regular;
      line-height: $line-height-small;
    }
    .course-details-action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .custom-rating {
        gap: var(--chakra-space-4);
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &:hover {
    background: $color-default-white;
    background-image: none !important;
    border: 1px solid $color-link-hover-color;

    .course-details {
      color: $color-default-black;
      background: none;
    }
  }

  .menu-list-container {
    width: 198px;
    .dropdown-menu-item {
      padding: var(--chakra-space-3);
      .menu-image {
        width: 24px;
        height: 24px;
      }
    }
  }
}
