@import "_variables.scss";

.your-item-main-holder{
    padding:  calc(var(--chakra-space-12) + 2px);
    .your-item-title-container{
        margin-bottom: calc(var(--chakra-space-12) + 2px);
        justify-content: space-between;
        .your-item-title{
            font-size: calc(var(--chakra-space-9) + 1.5px);
            font-weight: $font-weight-600;
            line-height: calc(var(--chakra-space-12) + 2px);
            margin-bottom: calc(var(--chakra-space-4) + 2px);
        }
        .your-item-subtitle{
            font-size: calc(var(--chakra-space-6) + 1px);
            font-weight: $font-weight-400;
            line-height: calc(var(--chakra-space-9) + 1.5px);
            color: $color-font-card;
        }
        .your-item-menu-button{
            border-radius: calc(var(--chakra-space-3) + 0.5px);
            height: calc(var(--chakra-space-16) + 8px);
            font-size: calc(var(--chakra-space-6) + 1px);
            font-weight: $font-weight-700;
            line-height: calc(var(--chakra-space-7) + 0.5px);
            border: 1px solid;
            border-color:$color-border-blue;
            color: $color-border-blue;
            background-color: $color-default-white;
            span{
                height: calc(var(--chakra-space-16) + 8px);
                padding: calc(var(--chakra-space-4) + 2px);
                display: flex;
                align-items: center;
            }
            .chakra-button__icon {
                font-size: calc(var(--chakra-space-8) + 2px);
                border-left: 1px solid;
                margin: 0;
            }
            &:hover,&[data-active]{
                border-color:$color-default-white;
                color: $color-default-white;
                background-color: $color-border-blue;
            }
        }
        .menu-list-container {
            width: calc(var(--chakra-space-96) + var(--chakra-space-20));
            overflow-y: auto;
            box-shadow: 0 calc(var(--chakra-space-1) + 1.5px) calc(var(--chakra-space-3) + 0.5px) $color-toast-box-shadow;
            border-radius: var(--chakra-space-8);
            padding: var(--chakra-space-4) calc(var(--chakra-space-6) + 1px);
            .dropdown-menu-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: var(--chakra-space-20);
            gap: var(--chakra-space-4);
            &:hover{
                background-color: transparent;
            }
            &:focus{
                background-color: transparent;
            }
            .menu-image {
                width: calc(var(--chakra-space-9) + 1.5px);
                height: calc(var(--chakra-space-9) + 1.5px);
                margin: 0;
            }
            .menu-text {
                color: $color-default-font-color;
                font-family: $font-family-semi-bold;
                font-size: 16px;
                font-weight: $font-weight-700;
                line-height: 18px;
                &:hover{
                    background-color: transparent;
                }
            }
            }
        }
    }
    .no-data-message{
        width: 100%;
        justify-content: center;
        height: var(--chakra-space-80);
    }
}