@import "_variables.scss";

.main-holder {
  align-items: flex-start;
  margin: var(--chakra-space-8) 0;

  .detail-checkbox {
    .chakra-checkbox__control {
      width: calc(var(--chakra-space-9) + 1.5px) !important;
      height: calc(var(--chakra-space-9) + 1.5px) !important;
      svg {
        font-size: calc(var(--chakra-space-4) + 2px) !important;
      }
    }
  }

  .detail-item-container {
    width: 100%;
    border-radius: calc(var(--chakra-space-6) + 1px);
    border: 1px solid $color-border;
    &.active {
      border-color: $color-link-hover-color;
      .detail-item-data {
        background-color: $color-btn-secondary-hover;
        border-radius: calc(var(--chakra-space-6) + 1px)
          calc(var(--chakra-space-6) + 1px) 0 0;
      }
      .detail-item-divider {
        border-color: $color-link-hover-color;
      }
    }
    .detail-item-data {
      align-items: flex-start;
      padding: var(--chakra-space-13);
      gap: var(--chakra-space-8);
      .detail-item-image {
        height: var(--chakra-space-40);
        width: var(--chakra-space-40);
        img {
          aspect-ratio: 1;
        }
      }
      .detail-item-content-holder {
        width: 80%;
        .detail-item-title {
          font-size: var(--chakra-space-8);
          font-weight: $font-weight-600;
          line-height: calc(var(--chakra-space-10) + 3px);
          color: $color-link-hover-color;
          margin-bottom: var(--chakra-space-1);
        }
        .detail-item-subtitle {
          font-size: calc(var(--chakra-space-5) + 1.5px);
          font-weight: $font-weight-400;
          line-height: var(--chakra-space-8);
          color: $color-font-card;
        }
      }
    }
    .detail-item-tag-container {
      justify-content: space-between;
      align-items: center;
      padding: var(--chakra-space-9) var(--chakra-space-10)
        var(--chakra-space-9) var(--chakra-space-13);
      box-sizing: border-box;
      height: var(--chakra-space-32);
      .detail-item-tag {
        display: flex;
        align-items: center;
        gap: var(--chakra-space-4);
        .tag-default {
          padding: var(--chakra-space-2) var(--chakra-space-4);
        }
        .tag-success,
        .tag-warning {
          padding: var(--chakra-space-2) var(--chakra-space-8);
        }
      }

      .menu-list-container {
        width: calc(var(--chakra-space-80) + var(--chakra-space-4));
        overflow-y: auto;
        box-shadow: 0 calc(var(--chakra-space-1) + 1.5px)
          calc(var(--chakra-space-3) + 0.5px) $color-toast-box-shadow;
        border-radius: var(--chakra-space-8);
        padding: var(--chakra-space-4) calc(var(--chakra-space-2) + 1px);
        .dropdown-menu-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: var(--chakra-space-20);
          gap: var(--chakra-space-4);
          &:hover {
            background-color: transparent;
          }
          &:focus {
            background-color: transparent;
          }
          .menu-image {
            width: calc(var(--chakra-space-9) + 1.5px);
            height: calc(var(--chakra-space-9) + 1.5px);
            margin: 0;
          }
          .menu-text {
            color: $color-default-font-color;
            font-family: $font-family-semi-bold;
            font-size: calc(var(--chakra-space-6) + 1px);
            font-weight: $font-weight-700;
            line-height: calc(var(--chakra-space-7) + 0.5px);
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
      .ellipsis-button {
        height: var(--chakra-space-14);
        width: var(--chakra-space-14);
        cursor: pointer;

        .share-icon,
        .fast-share-icon {
          width: calc(var(--chakra-space-9) + 1.5px);
          height: calc(var(--chakra-space-9) + 1.5px);
        }

        .fast-share-icon {
          display: none;
        }

        &:hover {
          background-color: $color-sidebar-hover-bg;
          .ellipsis-icon .path {
            fill: $color-link-hover-color;
          }
          .share-icon {
            display: none;
          }
          .fast-share-icon {
            display: block;
          }
        }

        &[data-active] {
          background-color: $color-sidebar-hover-bg;
          .ellipsis-icon .path {
            fill: $color-link-hover-color;
          }
        }
      }
    }
    .detail-item-divider {
      opacity: 1;
      border-color: $color-border;
    }
  }
}
